<template>
  <div id="BSCM" class="buybox supernettFont"> 
    <div class="buybox_right">
      <div class="wow fadeInDown">
        The culture of $BSCM has become a symbol encouraging people to explore the unknown and embrace opportunities. 
        Users who embrace $BSCM are those in the digital culture and cryptocurrency fields who are seeking luck and opportunities.
      </div>
      </div>
  </div>
</template>
<script>
export default {
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  }
}
</script>

<style lang="scss" scoped>
.buybox{
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px;
}
.buybox::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  background: url('../../assets/image/line.png') no-repeat center center/100%;
}
.buybox_right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1057px;
  height: 171px;
  background: url('../../assets/image/bscm/bg_02.png') no-repeat center center/100%;
  >div{
    max-width: 800px;
    font-weight: normal;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    font-style: normal;
  }
}
@media screen and (max-width:768px) {
  .buybox{
    width: 100%;
    margin-top: 0px;
    padding-bottom: 80px;
    &::before{
      display: none;
    }
    h2{
      font-size: 30px;
      margin: 0px 0px 50px;
    }

  }
  .buybox_right{
    width: 100%;
    max-width: 335px;
    min-height: 341px;
    background: url('../../assets/image/bscm/bg_02_m.png') no-repeat center center/100% 100%;
    >div{
      max-width: 295px;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>